import React, { memo, MouseEvent, useEffect, useState } from 'react';
import Reload from '../../../../svg/reload';
import SendMessage from '../../../../svg/sendMesage';
import { getStorageItemByName, setStorageItemByName } from '../../../../services/localStorage';
import { ConversationMessageRequest, ConversationMessageResponse } from '../../../../api/chat/types';
import { postMessageHandler } from '../../../../api/chat/chat';
import { toast } from 'react-toastify';
import { useAppStorageContext } from '../../../../context/useAppStorageContext';

interface IProps {
  color?: string;
  createNewConversationIfNotExisting: () => Promise<string>;
}

const getQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get('accountId');
  const agentId = urlParams.get('agentId');

  return {
    accountId,
    agentId,
  };
};

const MessageInput = ({ color = '#9966ff', createNewConversationIfNotExisting }: IProps) => {
  const { dispatch } = useAppStorageContext();

  /** State */
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const isDisabled = !message.trim().length || isLoading;

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {

    setMessage(event.target.value);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const textarea = e.currentTarget;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const conversationId = getStorageItemByName('conversationId');


    if (e.key === 'Enter') {
      if (!e.metaKey && !e.shiftKey) {
        e.preventDefault();
        await handleMessageSend(conversationId);
      } else if (e.shiftKey) {
        e.preventDefault();

        // Insert a new line at the cursor position
        const newMessage =
          message.substring(0, selectionStart) +
          '\n' +
          message.substring(selectionEnd);
        setMessage(newMessage);

        setTimeout(() => {
          textarea.selectionStart = selectionEnd + 1;
          textarea.selectionEnd = selectionEnd + 1;
          const wrapper: HTMLDivElement | null =
            document.querySelector('#input-wrapper');
          if (wrapper) {
            wrapper.style.height = `42px`;
            // wrapper.style.height = `${textarea.scrollHeight + 14}px`;
          }
        }, 0);
      }
    }

    setTimeout(() => {
      // textarea.style.height = `${textarea.scrollHeight || 18}px`;
      textarea.style.minHeight = '18px';
      textarea.style.height = '100%';
      textarea.style.maxHeight = '102px';
    }, 0);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const conversationId = getStorageItemByName('conversationId');
    await handleMessageSend(conversationId);
  };

  const handleResetConversation = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const accountId = getStorageItemByName('accountId');
    localStorage.clear();
    if (accountId) {
      setStorageItemByName('accountId', accountId);
    }
    dispatch({ type: 'MESSAGES', payload: [] });
  };

  const handleMessageSend = async (conversationId: string | null) => {
    try {
      const conversationId = getStorageItemByName('conversationId');
      const returnedConversationId = await createNewConversationIfNotExisting();
      if (message.trim()) {
        setIsLoading(true);
        const { accountId: accIdFromUrl } = getQueryParams();
        const accIdFromStorage = getStorageItemByName('accountId');
        const accountId = accIdFromUrl || accIdFromStorage;
        if (!accountId) {
          throw Error('Account id is not defined!');
        }

        const request: ConversationMessageRequest = {
          conversationId: conversationId || returnedConversationId || undefined,
          message: {
            role: 'user',
            content: [
              {
                type: 'text',
                text: message.trim(),
              },
            ],
          },
        };

        const response =
          await postMessageHandler<ConversationMessageResponse>(request);

        if (response.kind === 'success' && response.body) {
          dispatch({ type: 'MESSAGES', payload: response.body.messages });

          if (!conversationId) {
            setStorageItemByName('conversationId', response.body.id);
          }
        } else {
          const { body } = response;
          toast(`${response.message}: ${body?.message}`, {
            type: 'error',
          });
        }
        setIsLoading(false);
        setMessage('');
      }
    } catch (err) {
      if (typeof err === 'string') {
        toast(err, { type: 'error' });
      }
      console.log(err);
    }
  };

  useEffect(() => {
    const { accountId, agentId } = getQueryParams();

    if (accountId && accountId !== localStorage.getItem('accountId')) {
      localStorage.clear();
      setStorageItemByName('accountId', accountId);
    }

    if (agentId && agentId !== localStorage.getItem('agentId')) {
      setStorageItemByName('agentId', agentId);
    }
  }, []);

  useEffect(() => {
    const textarea: HTMLTextAreaElement | null =
      document.querySelector('#inputField');
    const wrapper: HTMLDivElement | null =
      document.querySelector('#input-wrapper');

    if (!message.length) {
      if (textarea) {
        textarea.style.height = '18px';
      }

      if (wrapper) {
        wrapper.style.height = `44px`;
      }
    }

    const onInput = () => {
      if (wrapper && textarea) {
        wrapper.style.height = `42px`;
        // wrapper.style.height = `${textarea.scrollHeight + 14}px`;
      }
    };

    if (textarea) {
      // textarea.style.height = `${textarea.scrollHeight || 18}px`;
      textarea.style.minHeight = '18px';
      textarea.style.height = '100%';
      textarea.style.maxHeight = '102px';
      textarea.addEventListener('input', onInput);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('input', onInput);
      }
    };
  }, [message]);

  return (
    <form onSubmit={handleSubmit} className="chat-footer">
      <button
        type="button"
        className="reset-button"
        onClick={handleResetConversation}>
        <Reload fill={color} />
        <p className="reset-button-title" style={{ color }}>
          Restart conversation
        </p>
      </button>
      <div className="input-container">
        <div className="input-wrapper" id="input-wrapper">
          <textarea
            id="inputField"
            className="input"
            autoFocus
            value={message}
            onChange={handleMessageChange}
            onKeyDown={onKeyDown}
            placeholder="Type your message"
          />
        </div>
        <button
          disabled={isDisabled}
          type="submit"
          className="send-button"
          style={{ backgroundColor: color }}>
          {isLoading ? <div className="loader"></div> : <SendMessage />}
        </button>
      </div>
    </form>
  );
};

export default memo(MessageInput);
