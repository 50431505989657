import React, { useEffect, useRef } from 'react';
import CustomMessage from '../CustomMessage/CustomMessage';
import MarkdownMessage from '../MarkdownMessage/MarkdownMessage';
import { Message } from '../../../../api/chat/types';

interface IMessageProps {
  message: Message;
}

const RenderMessage = ({ message }: IMessageProps) => {
  const { role, content } = message;
  const isUserMessage = role === 'user';
  const isAssistantMessage = ['system', 'assistant'].includes(role);

  return (
    <div id={`${role}-paragraph-container`}>
      <p className="paragraph" id={`paragraph-${role}`}>
        {isUserMessage && <CustomMessage content={content} />}
        {isAssistantMessage && <MarkdownMessage content={Array.isArray(content)? content[0].text :content as string} />}
      </p>
    </div>
  );
};

interface IMessagesProps {
  isVisible: boolean;
  messages: Message[];
}

const Messages = ({ messages, isVisible }: IMessagesProps) => {
  const messagesRef = useRef<HTMLUListElement | null>(null);
  const lastMessageRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (lastMessageRef.current && 'scrollIntoView' in lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [messages, isVisible]);

  return (
    <ul  ref={messagesRef}>
      {messages?.map((message, index) => (
        <li
          key={index}
          ref={index === messages.length - 1 ? lastMessageRef : null}>
          <RenderMessage message={message} />
        </li>
      ))}
    </ul>
  );
};

export default Messages;
